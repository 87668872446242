
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import DSAPLogo from '../images/dsap-logo.png'


const Form = (props) => {
    const formRef = useRef(null);
    const scriptUrl = "https://script.google.com/macros/s/AKfycbwsyafjjrRJAtHyLCUu9dVb-aH99hjf3X4wnQL0b4Odr-M_Gui6CjbrrjsrrU1PhUUGIg/exec";
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        fetch(scriptUrl, {
            method: 'POST',
            body: new FormData(formRef.current)
        })
        .then(res => {
            setLoading(false);
            console.log(res);
            navigate("/success")
        })
        .catch(err => {
            console.log(err)
            window.alert("Submission failed");
        });
    }

    return (
        <>
            <div className="  sm:mx-auto sm:w-full sm:max-w-md flex items-center mt-6">
                <img className="h-20 w-auto" src={DSAPLogo} alt="DSAP Logo" />
                <h1 className="mt-2 text-left text-xl mb-2 font-bold uppercase text-gray-900 ml-4">
                    DSAP NATIONAL CONVENTION 2023</h1>
            </div>

            <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10">

                    <form ref={formRef} onSubmit={handleSubmit} className="mb-0">

                        <div className='space-y-6'>
                            <div>
                                <label htmlFor="NAME" className="block text-sm font-medium text-gray-700">Name</label>
                                <div className="mt-1">
                                    <input id="NAME" name="NAME" type="text" autoComplete="NAME" required 
                                        className="w-full border border-gray-300 rounded-lg shadow-sm " />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="DRUGSTORE NAME" className="block text-sm font-medium text-gray-700">Drugstore Name</label>
                                <div className="mt-1">
                                    <input id="DRUGSTORE NAME" name="DRUGSTORE NAME" type="text" autoComplete="DRUGSTORE-NAME" required 
                                        className="w-full border border-gray-300 rounded-lg shadow-sm " />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="DRUGSTORE ADDRESS" className="block text-sm font-medium text-gray-700">Drugstore Address</label>
                                <div className="mt-1">
                                    <input id="DRUGSTORE ADDRESS" name="DRUGSTORE ADDRESS" type="text" autoComplete="DRUGSTORE-ADDRESS" required 
                                        className="w-full border border-gray-300 rounded-lg shadow-sm " />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="CONTACT NUMBER" className="block text-sm font-medium text-gray-700">Contact Number</label>
                                <div className="mt-1">
                                    <input id="CONTACT NUMBER" name="CONTACT NUMBER" type="text" autoComplete="CONTACT-NUMBER" required 
                                        className="w-full border border-gray-300 rounded-lg shadow-sm" />
                                </div>
                            </div>
                        </div>

                        <div className='mt-8'>
                            <button type="submit" 
                                disabled={loading}
                                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-wait">
                                    {loading ? <span>Submitting, please wait...</span> : <span>Submit</span>}
                                </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Form;