
import './App.css';

import { Routes, Route } from 'react-router-dom'

import Layout from './components/Layout';
import Form from './components/Form';
import FormSubmitted from './components/FormSubmitted';

function App() {
  return (
      <div className="App">
        <Routes>
          <Route element={<Layout/>}>
            <Route exact path='/' element={<Form/>}/>
            <Route exact path='/success' element={<FormSubmitted/>}/>
          </Route>
        </Routes>
      </div>
  );
}

export default App;
