import DKTLogo from '../images/dkthealth-logo.png'

const FormSubmitted = () => {
    return (
        <>
        <div className="mt-8 px-6 py-9 bg-white rounded max-w-2xl mx-auto">

            <strong className="text-2xl mb-12 font-medium">Thank you for being our <span className="text-blue-400">TRUST<span className="text-black">ed</span> Reproductive Health</span> partner!</strong>

            <p className="text-2xl mt-12 font-thin">Please claim your TRUST umbrella at <img src={DKTLogo} className="w-24 inline mb-2" alt="DKT Logo"/> booth!</p>

        </div>
        </>
    )
}

export default FormSubmitted;