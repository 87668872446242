import { Outlet } from 'react-router-dom'
import DKTLogo from '../images/dkthealth-logo.png'

const Layout = () => {
    return (
        <>
            <div className='min-h-screen flex flex-col'>
                <div className="sm:mx-auto sm:w-full sm:max-w-2xl flex flex-col items-center py-6">
                    <img className=" h-20 w-auto" src={DKTLogo} alt="DKT Logo" />
                    <h2 className="mt-2 text-center text-3xl font-thin uppercase text-gray-900 block">Registration</h2>
                </div>
            
                <div className="flex-grow bg-gray-100 py-12 justify-center px-6 lg:px-8">
                    <Outlet/>
                </div>
            </div>
        </>
    )
}

export default Layout;